import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import ServiceItem from './service-item';

import convertBold from '../../util/convertBold';

const StyledServices = styled.div`
	display: flex;
	.left {
		width: 100%;
		padding: 3rem 1rem 3rem ${(p) => p.theme.gutter};
		background-color: ${(p) => p.theme.color.purple};
		color: #fff;
		h2,
		strong {
			color: #fff;
			margin-bottom: 1em;
		}

		ul {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: flex-start;

			.column {
				width: 50%;
			}
		}
	}
	@media all and (min-width: 1200px) {
		.left {
			padding: 5rem 0 5rem ${(p) => p.theme.gutterWide};
		}
	}
	@media all and (min-width: 1600px) {
		.left {
			padding: 6rem 0 6rem ${(p) => p.theme.gutterXWide};
		}
	}
	@media all and (max-width: 868px) {
		&.warehouse {
			flex-wrap: wrap;
			.left {
				width: 100%;
				padding: 3rem ${(p) => p.theme.gutter};
			}
			.right {
				width: 100%;
				height: 18rem;
			}
		}
	}
	@media all and (max-width: 600px) {
		flex-wrap: wrap;
		.left {
			width: 100%;
			padding: 3rem ${(p) => p.theme.gutter};

			ul {
				flex-direction: column;
				.column {
					width: 100%;
					padding-top: 0;
					padding-bottom: 0;
				}
			}
		}
		.right {
			width: 100%;
			height: 10rem;
		}
	}
	@media all and (max-width: 500px) {
		.left {
			padding: 2.5rem ${(p) => p.theme.gutterMobile};
		}
	}
	@media all and (max-width: 420px) {
		.right {
			background-size: 120%;
		}
	}
`;

const Services = ({ title, list, page }) => {
	const listHalf = Math.ceil(list.length / 2);
	const col1Items = list.slice(0, listHalf);
	const col2Items = list.slice(listHalf);

	return (
		<StyledServices className={page}>
			<div className="left">
				<h2
					dangerouslySetInnerHTML={{
						__html: convertBold(title),
					}}
				/>
				<ul>
					<div className="column">
						{col1Items.map((item, index, list) => {
							return <ServiceItem key={index} item={item} />;
						})}
					</div>
					<div className="column">
						{col2Items.map((item, index, list) => {
							return <ServiceItem key={index} item={item} />;
						})}
					</div>
				</ul>
			</div>
		</StyledServices>
	);
};

export default Services;

export const ServicesFragment = graphql`
	fragment services on PagesJson {
		services {
			title
			list {
				title
				text
			}
		}
	}
`;
