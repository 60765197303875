import React from 'react';
import styled from 'styled-components';

const StyledLinks = styled.div`
	padding: 4rem ${(p) => p.theme.gutter};
	display: none !important;
	h2 {
		text-align: center;
		margin-bottom: 1rem;
	}
	.link-container {
		display: flex;
		flex-wrap: wrap;
		width: 60%;
		margin: 2rem auto 0;
		a {
			text-align: center;
			width: 33.3%;
			padding: 0.7rem 0;
			span {
				transition: 0.5s;
				color: ${(p) => p.theme.color.red};
				border-bottom: 1px solid ${(p) => p.theme.color.red};
				&:hover {
					transition: 0.5s;
					color: ${(p) => p.theme.color.purple};
					border-bottom: 1px solid ${(p) => p.theme.color.purple};
				}
			}
		}
	}
	@media all and (min-width: 1200px) {
		padding: 4rem ${(p) => p.theme.gutterWide};
	}
	@media all and (min-width: 1600px) {
		padding: 6rem ${(p) => p.theme.gutterXWide};
	}
	@media all and (max-width: 500px) {
		padding: 3rem ${(p) => p.theme.gutterMobile};
		.link-container {
			width: 90%;
		}
	}
`;

const Links = ({ title, copy, links }) => {
	return (
		<StyledLinks>
			<h2>{title}</h2>
			<p>{copy}</p>
			<div className="link-container">
				{links.map((item, index) => (
					<a href={item} key={index}>
						<span>{`LINK ${index + 1}`}</span>
					</a>
				))}
			</div>
		</StyledLinks>
	);
};

export default Links;
