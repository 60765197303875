import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledLi = styled.li`
	margin-bottom: 0.75em;
	padding-left: 1em;
	position: relative;
	break-inside: avoid;

	&::before {
		content: '+';
		position: absolute;
		left: 0;
		transition: 300ms;
	}

	h3 {
		font-size: 1.5rem;
		font-weight: 500;
	}

	&.has-text {
		h3 {
			transition: 300ms;
			cursor: pointer;
		}

		p {
			font-size: 1.2rem;
			font-family: ${(p) => p.theme.fonts.heading};
			height: 0;
			overflow: hidden;
			opacity: 0;
			padding: 0;
			margin: 0;
			transition: 300ms ease-in;
		}

		&:hover {
			&::before {
				color: ${(p) => p.theme.color.red};
			}

			h3 {
				color: ${(p) => p.theme.color.red};
			}
		}

		@media (max-width: 600px) {
			p {
				font-size: 1rem;
			}
		}

		&.active {
			p {
				height: ${(p) => (p.textHeight ? `${p.textHeight}px` : 'auto')};
				opacity: 1;
				overflow: visible;
				padding: 1em 0 1.5em;
				border-bottom: 1px solid #ffffff;
				margin-bottom: 1.5em;
			}

			@media (min-width: 1200px) {
				p {
					margin-right: 3em;
				}
			}
		}
	}
`;

const ServiceItem = ({ item }) => {
	const itemRef = useRef(null);
	const [textHeight, setTextHeight] = useState(undefined);
	const [isActive, setIsActive] = useState(true);

	useEffect(() => {
		const itemText = itemRef.current.querySelector('p');

		if (itemText) {
			setTextHeight(itemText.clientHeight);
			console.log(textHeight);
			setIsActive(false);
		}
	}, []);

	const toggleClass = () => {
		setIsActive(!isActive);
	};

	return !!item.text && !!item.text !== '' ? (
		<StyledLi
			ref={itemRef}
			className={`has-text${isActive ? ' active' : ''}`}
			textHeight={textHeight}
			onClick={() => toggleClass()}
		>
			<h3>{item.title}</h3>
			<p>{item.text}</p>
		</StyledLi>
	) : (
		<StyledLi ref={itemRef}>
			<h3>{item.title}</h3>
		</StyledLi>
	);
};

export default ServiceItem;
