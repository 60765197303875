import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import convertBold from '../../util/convertBold';

const StyledForm = styled.div`
	padding: 4rem 0;
	text-align: center;
	background-image: url(${(p) => p.img});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	h2,
	strong {
		color: #fff;
		margin-bottom: 0.5rem;
	}
	.form-container {
		width: 50%;
		margin: 0 auto;
		.field-row {
			display: flex;
			.field {
				width: 50%;
				&:first-child {
					margin-right: 1rem;
				}
				&:last-child {
					margin-left: 1rem;
				}
			}
		}
		label {
			display: block;
			color: #fff;
			margin: 15px 0 5px;
			text-align: left;
			&::after {
				content: '*:';
			}
		}
		input {
			width: 100%;
			padding: 5px;
			height: 2rem;
		}
		textarea {
			width: 100%;
			resize: none;
			padding: 5px;
			height: 7rem;
			margin-bottom: 1rem;
		}
		button {
			padding: 0.5rem 2.5rem;
			cursor: pointer;
		}
		.thanks {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			color: #fff;
		}
	}
	h4 {
		color: #fff;
		width: 50%;
		margin: 2rem auto 0;
		text-transform: uppercase;
		font-size: 1.5rem;
		line-height: 2rem;
		a {
			color: #fff;
			font-weight: 700;
			position: relative;
			&::after {
				content: '';
				background-color: #fff;
				transition: 0.5s;
				width: 100%;
				height: 1px;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				opacity: 0;
			}
			&:hover::after {
				opacity: 1;
			}
		}
	}
	@media all and (min-width: 1600px) {
		padding: 6rem 0;
		h4,
		h4 a {
			font-size: 1.8rem;
			line-height: 2.3rem;
		}
		label {
			font-size: 1.3rem;
		}
	}
	@media all and (max-width: 768px) {
		.form-container {
			width: 60%;
		}
		h4 {
			width: 60%;
		}
	}
	@media all and (max-width: 668px) {
		padding: 3rem ${(p) => p.theme.gutter};
		.form-container {
			width: 100%;
		}
		h4 {
			width: 100%;
		}
	}
	@media all and (max-width: 450px) {
		padding: 3rem ${(p) => p.theme.gutterMobile};
		h4 {
			font-size: 1.3rem;
			line-height: 1.7rem;
		}
	}
	@media all and (max-width: 420px) {
		.form-container .field-row {
			flex-wrap: wrap;
			.field {
				width: 100%;
				margin: 0 !important;
			}
		}
	}
`;

const Form = ({ title, phone, img, path }) => {
	const [thanks, setThanks] = useState(false);
	const [height, setHeight] = useState(294);

	useEffect(() => {
		setThanks(window.location.search === '?thanks=true');
		const handleResize = () => {
			if (window.innerWidth > 1600) setHeight(313);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	return (
		<StyledForm img={img} id="form">
			<h2
				dangerouslySetInnerHTML={{
					__html: convertBold(title),
				}}
			/>
			<div className="form-container">
				{thanks && (
					<div
						className="thanks"
						id="mobileThanksHeight"
						style={{ height: `${height}px` }}
					>
						<h2>Thanks for submitting.</h2>
						<p>You will hear from us shortly.</p>
					</div>
				)}

				{!thanks && (
					<form
						method="post"
						name="Services Form"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
						action={`${path}?thanks=true#form`}
					>
						<input type="hidden" name="form-name" value="Services Form" />
						<input type="hidden" name="bot-field" />
						<input
							type="hidden"
							name="source page"
							value={path.substring(1, path.length - 1)}
							readOnly
						/>
						<div className="field-row">
							<div className="field">
								<label htmlFor="name">Name</label>
								<input type="text" id="name" name="name" required aria-required="true"/>
							</div>
							<div className="field">
								<label htmlFor="email">Email</label>
								<input type="email" id="email" name="email" required aria-required="true"/>
							</div>
						</div>
						<div className="field">
							<label htmlFor="message">Message</label>
							<textarea type="text" id="message" name="message" required aria-required="true"/>
						</div>
						<button className="redButton" type="submit">
							SUBMIT
						</button>
					</form>
				)}
			</div>
			<h4>
				Or, call us toll free at <a href={`tel:${phone.raw}`}>{phone.format}</a>{' '}
				to speak with a customer service representative
			</h4>
		</StyledForm>
	);
};

export default Form;
