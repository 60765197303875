import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import convertBold from '../../util/convertBold';
import mdToHtml from '../../util/mdToHtml';

const StyledCopyColumns = styled.div`
	display: flex;
	h2 {
		margin-bottom: 1em;
	}

	.left {
		width: 50%;
		padding: 3rem 1rem 3rem ${(p) => p.theme.gutter};
	}

	.right {
		background-image: url(${(p) => p.img});
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		width: 50%;
	}

	@media all and (min-width: 1200px) {
		.left {
			padding: 5rem 3rem 5rem ${(p) => p.theme.gutterWide};
		}
	}

	@media all and (min-width: 1600px) {
		.left {
			padding: 6rem 5rem 6rem ${(p) => p.theme.gutterXWide};
		}
	}

	@media all and (max-width: 600px) {
		flex-wrap: wrap;
		.left {
			width: 100%;
			padding: 3rem ${(p) => p.theme.gutter};
		}
		.right {
			width: 100%;
			height: 10rem;
		}
	}
	@media all and (max-width: 500px) {
		.left {
			padding: 2.5rem ${(p) => p.theme.gutterMobile};
		}
	}
	@media all and (max-width: 420px) {
		.right {
			background-size: 120%;
		}
	}
`;

const CopyColumns = ({ title, left, img }) => {
	return (
		<StyledCopyColumns img={img}>
			<div className="left">
				<h2
					dangerouslySetInnerHTML={{
						__html: convertBold(title),
					}}
				/>
				<div dangerouslySetInnerHTML={{ __html: mdToHtml(left) }} />
			</div>
			<div className="right" role="img" aria-label="image of customs document"/>
		</StyledCopyColumns>
	);
};

export default CopyColumns;

export const ServicesCopyFragment = graphql`
	fragment servicesCopy on PagesJson {
		columns {
			title
			textLeft
			img
		}
	}
`;
